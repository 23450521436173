import React from 'react'
import Layout from '../components/layout/layout'

const ErrorPage = () => {
    return(
        <Layout>
            <p>This page doesn't exist</p>
        </Layout>
    )
}

export default ErrorPage 